import React, { useState, useEffect, useRef } from 'react';

import { Col, Row, Container, Button } from 'reactstrap';

//componentes
import PageHeader from '../../components/common/elems/PageHeader';
import LoadingLayer from '../../components/loaders/LoadingLayer';
import Seat from './elems/Seat';
import Asistente from './elems/Asistente';
import AsistentLoader from '../../components/loaders/AsistentLoader';

//forms
import { Formik, Form, Field } from 'formik';
import InputForm from '../../components/common/forms/InputForm';
import StackedCheckbox from '../../components/common/forms/StackedCheckbox';

//services
import * as accessApi from '../../api/accessEventApi';

//notifications
import * as notify from '../../utils/notify';
import LateralModal from '../../components/common/modals/LateralModal';

const MapView = ({ stepBackFn, eventData, roomData }) => {
	useEffect(() => {
		getMapDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [loadingMap, setLoadingMap] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [mapData, setMapData] = useState(null);
	const [capacityData, setCapacityData] = useState(null);
	const [showAsistentsModal, setShowAsistentsModal] = useState(false);

	const getMapDetails = async () => {
		setPageLoading(true);
		const data = await accessApi.getDetailMap({
			eventoId: eventData.id,
			salaId: roomData.id,
		});
		if (data && data.succeeded) {
			setMapData(data.data);
			setCapacityData(data.data.capacity);
		} else {
			notify.error(`Ups! Ha ocurrido un error. ${data.message}`);
		}

		setPageLoading(false);
	};

	const toggleModalAsistentes = () => {
		!showAsistentsModal && getAsistentes();
		setShowAsistentsModal(!showAsistentsModal);
	};

	//------------- ASISTENTES
	const formikRef = useRef();

	const handleFilterAsistentes = async (values, formProps) => {
		const updatedValues = {
			...values,
		};
		getAsistentes(updatedValues);
	};

	const [asistentesList, setAsistentesList] = useState([]);
	const [asistentesTotal, setAsistentesTotal] = useState(0);
	const [asistentesTotalDentro, setAsistentesTotalDentro] = useState(0);
	const [asistentesTotalPorEntrar, setAsistentesTotalPorEntrar] = useState(0);
	const [isLoadingAsistentes, setIsLoadingAsistentes] = useState(false);
	const [asistenteSelected, setAsistenteSelected] = useState(null);

	const getAsistentes = async (filters) => {
		selectAsistente(null);
		setIsLoadingAsistentes(true);
		const data = await accessApi.getAssistants({
			...filters,
			eventoId: eventData.id,
			salaId: roomData.id,
		});
		if (data && data.succeeded) {
			setAsistentesList(data.data.asistentes);
			setAsistentesTotal(data.data.totalAsistentes);
			setAsistentesTotalDentro(data.data.totalAsistentesAsignados);
			setAsistentesTotalPorEntrar(data.data.totalAsistentesPorAsignar);
		}
		setIsLoadingAsistentes(false);
	};

	const selectAsistente = (asistente) => {};

	return pageLoading ? (
		<LoadingLayer visible={pageLoading} />
	) : (
		<>
			<PageHeader title={roomData.nombre} stepBackFn={stepBackFn}>
				<Button
					className={`btn-sec-dark btn-icon ${
						asistenteSelected ? 'w-cto' : ''
					}`}
					onClick={toggleModalAsistentes}
				>
					<i className="fticon-user"></i>
				</Button>
			</PageHeader>

			{loadingMap ? (
				<LoadingLayer visible={loadingMap} />
			) : (
				<>
					<Container fluid className="map-body">
						<Row>
							<Col md="6" className="mx-auto mt-2 mb-4">
								{capacityData && (
									<div className="border-box">
										<div className="total">
											<div className="bullet"></div>
											<div>
												<span>{capacityData.mapaSeats -
														capacityData.mapaOcuppedSeats || 0}/</span>
												{capacityData.mapaSeats || 0}
											</div>
										</div>
										<div className="ocupados">
											<i className="fticon-user"></i>
											<div>
												<span>{capacityData.mapaOcuppedSeats - capacityData.mapaOcuppedQR || 0}/</span>
												{capacityData.mapaSeats || 0}
											</div>
										</div>
										<div className="libres">
											<i className="fticon-user"></i>
											<div>
												<span>{capacityData.mapaOcuppedQR || 0}/</span>
												{capacityData.mapaSeats || 0}
											</div>
										</div>
									</div>
								)}
							</Col>
						</Row>

						<div className="map-scroll">
							<div className="map-canvas">
								{mapData &&
									mapData.areas &&
									mapData.areas.map((area, indexArea) => (
										<div
											key={indexArea}
											className="area-elem"
											style={{
												transform: `translate(${area.pos.x || 0}px, ${
													area.pos.y || 0
												}px)`,
												width: area.size.w,
												height: area.size.h,
											}}
										>
											<div
												className={`area-container idxcont-${indexArea}`}
												style={{
													transform: `rotate(${area.pos.z}deg)`,
													zIndex: 1,
												}}
											>
												<div className="cont-header">
													<div className="cont-name cont-planos">
														{area.type && area.type.type === 'seat' && (
															<>
																<div className="seat-name">{area.titulo}</div>
																<div className="seat-count">
																	{capacityData?.areas[area.id]
																		? capacityData?.areas[area.id].seats
																		: 0}
																</div>
															</>
														)}
													</div>
													{area.type &&
														area.type.type === 'seat' &&
														capacityData?.areas[area.id] && (
															<div className="aforo">
																<div className="total">
																	<div className="bullet"></div>
																	<div>
																		<span>
																			{capacityData?.areas[area.id].seats -
																				capacityData?.areas[area.id]
																					.ocuppedSeats}
																		</span>
																	</div>
																</div>
																<div className="sala">
																	<div className="bullet green"></div>
																	<div>
																		<span>
																			{
																				capacityData?.areas[area.id]
																					.ocuppedSeats
																			}
																		</span>
																	</div>
																</div>
															</div>
														)}
												</div>
												<div
													className={`cont-cont ${
														area.type && area.type.type === 'seat'
															? 'type-seat'
															: 'type-screen'
													}`}
												>
													{area.type && area.type.type === 'seat' ? (
														area.rows &&
														area.rows.map((row, indexRow) => (
															<div className="area-row" key={indexRow}>
																<div className="area-row-fila">
																	<span>{row.numFila}</span>
																</div>
																{row.seats &&
																	row.seats.map((seat, seatIndex) => {
																		return (
																			<div
																				key={seatIndex}
																				data-area={indexArea}
																				data-row={indexRow}
																				data-seat={seatIndex}
																				data-seatid={seat.id}
																				data-asistente={
																					seat.asistente ? true : false
																				}
																				data-seattype={seat.tipo}
																				className="area-row-seat"
																			>
																				<Seat seatDetails={seat} />
																			</div>
																		);
																	})}
															</div>
														))
													) : (
														<div className="area-screen">{area.titulo}</div>
													)}
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</Container>
					{showAsistentsModal && (
						<LateralModal
							isOpen={showAsistentsModal}
							title={`Asistentes (${asistentesTotal})`}
							toggleModal={toggleModalAsistentes}
						>
							<div className="ast-modal">
								<div className="ast-filter">
									<Formik
										innerRef={formikRef}
										enableReinitialize
										initialValues={{
											dentro: true,
											porentrar: true,
											nombreCompleto: '',
										}}
										validateOnChange={false}
										validateOnBlur={false}
										onSubmit={(values, actions) =>
											handleFilterAsistentes(values, actions)
										}
									>
										{({ values, setFieldValue, handleSubmit }) => {
											return (
												<Form
													autoComplete="off"
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															handleSubmit();
														}
													}}
												>
													<div className="row-form-filter">
														<Field
															label="Nombre o apellidos"
															placeholder="Nombre o apellidos"
															name="nombreCompleto"
															component={InputForm}
															isClearable
															extraClearFunction={handleSubmit}
														/>
													</div>

													<div className="row-status">
														<Row>
															<Col>
																<Field
																	label={`Dentro (${asistentesTotalDentro})`}
																	name="dentro"
																	reverse
																	fieldStack="dentro"
																	boolValue
																	component={StackedCheckbox}
																	onChange={(e) => {
																		handleSubmit();
																	}}
																/>
															</Col>
															<Col>
																<Field
																	label={`Por entrar (${asistentesTotalPorEntrar})`}
																	name="porentrar"
																	reverse
																	fieldStack="porentrar"
																	boolValue
																	component={StackedCheckbox}
																	onChange={(e) => {
																		handleSubmit();
																	}}
																/>
															</Col>
														</Row>
													</div>
												</Form>
											);
										}}
									</Formik>
								</div>
								<div className="ast-list">
								<div className="asistentes-list">
									{isLoadingAsistentes ? (
										<AsistentLoader />
									) : asistentesList && asistentesList.length > 0 ? (
										asistentesList.map((ast, astIx) => (
											<Asistente
												key={astIx}
												asistente={ast}
												ixAsistente={astIx}
												openAst={asistenteSelected}
												openFn={selectAsistente}
											/>
										))
									) : (
										<div className="no-results py-5">
											<h3>Sin resultados</h3>
											<p>No se han encontrado resultados</p>
										</div>
									)}
								</div>
								</div>
							</div>
						</LateralModal>
					)}
				</>
			)}
		</>
	);
};

export default MapView;
