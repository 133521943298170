import React from 'react';
import NoImage from '../../../css/images/icons/icon-image.svg';

const Image = ({ onErrorImageWidth, imageClass, hasLink, ...props }) => {
	return (
		<img
			onError={(e) => {
				e.target.src = NoImage;
				e.target.style = `width: ${onErrorImageWidth}px !important; border-radius:0; object-fit:contain;`;
			}}
			className={`${imageClass} ${hasLink ? 'pointer':''}`}
      alt={props.alt ? props.alt : 'Image'}
			{...props}
		/>
	);
};

export default Image;
