import React from 'react';
import { Col, Row, Container } from 'reactstrap';

//componentes
import PageHeader from '../../components/common/elems/PageHeader';
import ItemEvent from '../../components/common/elems/ItemEvent';

const ListEvents = ({ logOutFn, eventsData, showEventFn }) => {
	return (
		<>
			<PageHeader title={'Eventos'} logoutFn={logOutFn} />
			<Container>
				<Row>
					<Col md="12">
						<div className="row events-grid">
							{eventsData && eventsData.length > 0 ? (
								eventsData.map((event, index) => {
									return (
										<ItemEvent
											item={event}
											key={index}
											onClickFn={() => showEventFn(event.id)}
										/>
									);
								})
							) : (
								<div className="text-center py-5">
									No hay eventos disponibles
								</div>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default ListEvents;
