import { callApiGet, callApiPost } from './apiUtils';
const url_base = '/access';

export const getActualEvents = async (eventData) => {
	return await callApiPost(`${url_base}/getActualEvents`, eventData);
};

export const getEvent = async (eventID) => {
	return await callApiGet(`${url_base}/getEvent/${eventID}`);
};

export const scanEntrada = async (scanData) => {
	return await callApiPost(`${url_base}/scanQR`, scanData);
};

export const permitirAcceso = async (asistente) => {
	return await callApiGet(`${url_base}/permitirAcceso/${asistente}`);
};

export const getDetailMap = async (mapData) => {
	return await callApiPost(`${url_base}/getDetailMap`, mapData);
};

export const updateCapacities = async (eventData) => {
	return await callApiPost(`${url_base}/actualizarAsistencia`, eventData);
};

export const getAssistants = async (filters) => {
	return await callApiPost(`${url_base}/getAssistants`, filters);
};