import React, { useState } from 'react';

const Asistente = ({ asistente, openAst, openFn }) => {
	const [isOpened, setisOpened] = useState(false);

	// const isOpened = openAst
	// 	? openAst.invitadoId === asistente.invitadoId
	// 		? true
	// 		: false
	// 	: false;

	const openAsistente = () => {
		isOpened ? openFn(null) : openFn(asistente);
	};

	return (
		<div className="asistente">
			<div
				className={`asistente-row ${isOpened ? 'expanded' : ''}`}
				onClick={() => (isOpened ? setisOpened(false) : setisOpened(true))}
			>
				<div className="name">
					<div
						className={`icon-table icon-entrada 
					${asistente.fechaUsoQR != null ? 'dentro' : ''}`}
					>
						<i className="fticon-ticket"></i>
					</div>

					<span className="name-margin">{asistente.nombreContacto}</span>
				</div>
				<div className="totals">
					<span>
						{asistente.asistentesPorInvitadoDentro}/
						{asistente.asistentesPorInvitadoConEntrada}
					</span>
				</div>
			</div>
			<div className={`asistente-info ${isOpened ? 'opened' : ''}`}>
				{/*{asistente.totalAsistentes === 0 && (*/}
				{/*	<div className="w-seatinfo text-center py-5">*/}
				{/*		El asistente no tiene butacas asignadas*/}
				{/*	</div>*/}
				{/*)}*/}
				{asistente.asistencia &&
					asistente.asistencia.sitting.areas &&
					asistente.asistencia.noSitting.salas && (
						<div className="sitting-type">
							<div className="name">
								<i className="fticon-sitting"></i>
								<span>Sitting</span>
							</div>
							<div className="info">
								<span>{asistente.totalAsistentesConSittingSentados}</span>/
								{asistente.totalAsistentesConSitting}
							</div>
						</div>
					)}
				{/*{asistente.totalAsistentesConSittingSentados === 0 && (*/}
				{/*	<div className="w-seatinfo text-center py-5">*/}
				{/*		El asistente no tiene butacas asignadas*/}
				{/*	</div>*/}
				{/*)}*/}

				{asistente.asistencia &&
					asistente.asistencia.sitting &&
					asistente.asistencia.sitting.areas &&
					asistente.asistencia.sitting.areas.map((area, ixArea) => (
						<div key={ixArea} className="w-seatinfo">
							<div className="head">
								<div className="sala">{area.nombreSala}</div>
								<div className="zona">{area.nombreArea}</div>
							</div>
							{area.filas &&
								area.filas.length > 0 &&
								area.filas.map((fila, ixFila) => (
									<div key={ixFila} className="asientos">
										<div>
											{fila.numFila && (
												<>
													Fila <span className="name-fila">{fila.numFila}</span>
												</>
											)}
										</div>
										<div>
											{`Butaca/s `}
											<span>
												{fila.butacas.map((butaca, ixButaca) => (
													<span
														className={butaca.esPrincipal ? 'blue' : ''}
														key={ixButaca}
													>{`${butaca.texto}${
														fila.butacas.length > 1
															? ixButaca + 1 !== fila.butacas.length
																? ', '
																: ''
															: ''
													}`}</span>
												))}
											</span>
										</div>
									</div>
								))}
						</div>
					))}

				{asistente.asistencia &&
					asistente.asistencia.noSitting &&
					asistente.asistencia.noSitting.salas && (
						<>
							<div className="sitting-type">
								<div className="name">
									<i className="fticon-no-sitting"></i>
									<span>No sitting</span>
								</div>
								<div className="info">
									<span>{asistente.totalAsistentesSinSittingSentados}</span>/
									{asistente.totalAsistentesSinSitting}
								</div>
							</div>

							{asistente.asistencia.noSitting.salas.map((sala, ixArea) => (
								<div key={ixArea} className="w-seatinfo">
									<div className="head">
										<div className="sala">
											{sala.nombreSala}{' '}
											<span className="zona">{sala.nombreArea}</span>
										</div>
										<div>
											<span className="sala">{sala.totalOcupadasInvitado}</span>{' '}
											butaca{sala.totalOcupadasInvitado !== 1 ? 's' : ''}
										</div>
									</div>
								</div>
							))}
						</>
					)}
			</div>
		</div>
	);
};

export default Asistente;
