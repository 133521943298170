import { DateTimeFormatter, LocalDateTime, Instant } from '@js-joda/core';

export function getDate(date) {
	let _date = date;
	if (date && !(date instanceof Date)) _date = new Date(date);
	return _date;
}

export function getLocalDateNow() {
	return LocalDateTime.ofInstant(Instant.now());
}

export const sortArrayByKey = (key) => (a, b) => a[key] > b[key] ? 1 : -1;

export const removeLoginFromLabel = (str) => str.replace(/ *\([^)]*\) */g, '');

export function parseFullDate(date) {
	if (!date) return '';
	return (
		date.getFullYear() +
		'-' +
		('0' + (date.getMonth() + 1)).slice(-2) +
		'-' +
		date.getDate()
	);
}

export function parseDateTimeFromString(date, pattern = 'dd/MM/yy - HH:mm:ss') {
	if (!date) return '';
	const dateParsed = LocalDateTime.parse(date);
	return dateParsed.format(DateTimeFormatter.ofPattern(pattern));
}

export function mergeDateAndTime(dateString, timeString) {
	const datestr = dateString.split('T');
	return datestr[0] + 'T' + timeString + ':00';
}

export function compareHours(minTo, maxTo) {
	const date1 = new Date(minTo);
	const date2 = new Date(maxTo);
	return date1 > date2 ? true : false;
}

export function strToTimestamp(dateString) {
	return dateString + 'T00:00:00';
}

export const COLORS_NOCOLOR = [
	'transparent',
	'#bc60e6',
	'#ff60b2',
	'#ff345b',
	'#ff851c',
	'#1376a5',
	'#3ebad8',
	'#6dd845',
	'#b1961c',
	'#feb223',
];

export const COLORS_PLAIN = [
	'#bc60e6',
	'#ff60b2',
	'#ff345b',
	'#ff851c',
	'#1376a5',
	'#3ebad8',
	'#6dd845',
	'#b1961c',
	'#feb223',
];

export const IMAGE_TYPES_ALLOWED =
	'image/png, image/gif, image/jpeg, image/jpg';

export const SEAT_TYPES = [
	{ label: 'Texto', value: 'text' },
	{ label: 'Asiento', value: 'seat' },
	{ label: 'Espacio', value: 'space' },
	{ label: 'Pantalla', value: 'screen' },
];

export const EVENT_TYPES = [
	{ label: 'Anunciantes', value: '1' },
	{ label: 'Premiere', value: '0' },
];

export const colorToClass = (color) => {
	switch (color) {
		case '#1376a5':
			return 'blue';
		case '#bc60e6':
			return 'purple';
		case '#3ebad8':
			return 'lightbl';
		case '#4573d2':
			return 'darkblue';
		case '#ff851c':
			return 'orange';
		case '#feb223':
			return 'yellow';
		case '#6dd845':
			return 'green';
		case '#b1961c':
			return 'olive';
		case '#ff60b2':
			return 'pink';
		case '#ff345b':
			return 'red';
		case '#657488':
			return 'gray';
		default:
			return 'transparent';
	}
};

export const classCssToColor = (css) => {
	switch (css) {
		case 'blue':
			return '#1376a5';
		case 'purple':
			return '#bc60e6';
		case 'lightbl':
			return '#3ebad8';
		case 'darkblue':
			return '#4573d2';
		case 'orange':
			return '#ff851c';
		case 'yellow':
			return '#feb223';
		case 'green':
			return '#6dd845';
		case 'olive':
			return '#b1961c';
		case 'pink':
			return '#ff60b2';
		case 'red':
			return '#ff345b';
		case 'gray':
			return '#657488';
		default:
			return 'transparent';
	}
};
