import React from 'react';

import { Col, Row, Container } from 'reactstrap';

//componentes
import PageHeader from '../../components/common/elems/PageHeader';

const DetailEvent = ({ stepBackFn, nextStepFn, eventData }) => {
	return (
		<>
			<PageHeader title={eventData.nombre} stepBackFn={stepBackFn} />
			<Container className="event-body">
				<Row>
					<Col md="6" className="mb-4">
						<div
							className="box-event-options"
							onClick={() => nextStepFn('accessControl')}
						>
							<div className="options-img">
								<span className="fticon-location"></span>
							</div>
							<div className="options-text">Control de acceso al recinto</div>
						</div>
					</Col>
					<Col md="6" className="mb-4">
						<div
							className="box-event-options box-2"
							onClick={() => nextStepFn('roomControl')}
						>
							<div className="options-img">
								<span className="fticon-sitting"></span>
							</div>
							<div className="options-text">Control de acceso a la sala</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col className="mb-4" md="12">
						<div
							className="box-event-options box-3"
							onClick={() => nextStepFn('seatsControl')}
						>
							<div className="options-img">
								<span className="fticon-user"></span>
							</div>
							<span className="options-text">Control de aforo</span>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default DetailEvent;
