import React from 'react';
import _ from 'lodash';

import { Input, Label } from 'reactstrap';

const StackedCheckbox = ({
	label,
	fieldStack,
	reverse,
	boolValue,
	//Formik
	field,
	form,
	...props
}) => {
	const error = form && form.errors && _.get(form.errors, field.name);
	const isDisabled = props.disabled ? true : false;

	const handleSelect = (e) => {
		const { checked, name } = e.target;

		boolValue
			? form.setFieldValue(fieldStack, checked)
			: form.setFieldValue(
					fieldStack,
					checked
						? [...form.values[fieldStack], parseInt(name)]
						: form.values[fieldStack].filter((v) => v !== parseInt(name))
			  );

		props.onChange && props.onChange()
	};

	const isChecked = boolValue
		? form.values[fieldStack]
		: form.values[fieldStack]
		? form.values[fieldStack].includes(parseInt(field.name))
		: false;

	return (
		<div
			className={`input-type-checkbox stacked${isDisabled ? ' disabled' : ''}${
				error && typeof error === 'string' ? ' has-error' : ''
			}${isChecked ? ' checked' : ''}${reverse ? ' reversed' : ''}`}
		>
			<Label>
				{reverse && label}
				<Input
					id={field.name}
					type="checkbox"
					name={field.name}
					checked={isChecked}
					onChange={handleSelect}
				/>
				{!reverse && label}
			</Label>
		</div>
	);
};

export default StackedCheckbox;
