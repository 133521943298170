import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import Image from './Image';

//helpers
import { parseDateTimeFromString } from '../../../utils/helpers';

const ItemEvent = ({ item, onClickFn, ...props }) => {
	return (
		<Col className='event-box' md="4">
			<Card className="event-item" onClick={onClickFn}>
				<div className="header-image">
					{item.urlImagen ? (
						<Image
							src={item.urlImagen ? item.urlImagen : 'no-image'}
							onErrorImageWidth={38}
							alt={item.nombre}
							className="card-img-top"
						/>
					) : (
						<i className="fticon-image"></i>
					)}
				</div>
				<CardBody>
					<div className="event-info">
						<div className="event-name">{item.nombre}</div>
						<div className="event-date">
							{item.fechaEvento &&
								parseDateTimeFromString(item.fechaEvento, 'dd-MM-yyyy')}
							<span>{item.horaEvento}</span>
						</div>
						<div className="event-site">
							{item.eventoLugar && item.eventoLugar.nombre}
						</div>
					</div>				
				</CardBody>
			</Card>
		</Col>
	);
};

export default ItemEvent;
