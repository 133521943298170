import React, { useState, useEffect } from 'react';

//services
import * as accessApi from '../api/accessEventApi';
import { handleMsalLogout } from '../context/msalConfig';

//Components
import LoadingLayer from '../components/loaders/LoadingLayer';
import ListEvents from './eventos/ListEvents';
import DetailEvent from './eventos/DetailEvent';
import AccessControl from './eventos/AccessControl';
import RoomControl from './eventos/RoomControl';
import SeatsControl from './eventos/SeatsControl';
import QRScanPage from './eventos/QRScanPage';
import MapView from './eventos/MapView';

//notifications
//import * as notify from '../../utils/notify';

const AccessEventPage = () => {

	const [eventsData, setEventsData] = useState({});
	const [eventData, setEventData] = useState({});
	const [eventStep, setEventStep] = useState('eventList');
	const [backStep, setBackStep] = useState('eventList');
	const [titleData, setTitleData] = useState({});
	const [roomData, setRoomData] = useState(null);
	const [eventId, setEventId] = useState(null);

	const [viewSalaId, setViewSalaId] = useState(null);
	const [viewAccessId, setViewAccessId] = useState(null);

	const [pageLoading, setPageLoading] = useState(false);

	useEffect(() => {
		getEvents();
	}, []);

	const getEvents = async () => {
		const data = await accessApi.getActualEvents();
		setEventsData(data.data);
	};

	const logOut = () => {
		handleMsalLogout();
	};

	const showEvent = (eventId) => {
		setEventStep('eventDetail');
		getEvent(eventId);
	};

	const getEvent = async (eventId) => {
		setPageLoading(true);
		const data = await accessApi.getEvent(eventId);
		setEventData(data.data);
		setEventId(eventId);
		setPageLoading(false);
	};

	const nextStep = (nextStep) => {
		setEventStep(nextStep);
	};

	const stepBack = () => {
		const actual = eventStep;
		var back = '';

		if (backStep === '' || backStep === 'eventList') {
			if (actual === 'eventDetail') {
				back = 'eventList';
			} else if (
				actual === 'accessControl' ||
				actual === 'roomControl' ||
				actual === 'seatsControl'
			) {
				back = 'eventDetail';
			} else if (actual === 'mapView') {
				back = 'seatsControl';
			} else if (actual === 'scanner') {
				back = 'eventDetail';
			}
		} else {
			back = backStep;
			setBackStep('');
		}

		setEventStep(back);
	};

	const scan = (backStepAux, title, acceso = null, sala = null) => {
		setViewAccessId(acceso);
		setViewSalaId(sala);
		setBackStep(backStepAux);
		setTitleData(title);
		setEventStep('scanner');
	};

	const mapview = (roomData) => {
		setRoomData(roomData);
		setBackStep('seatsControl');
		setEventStep('mapView');
	};

	return (
		<>
			{pageLoading && <LoadingLayer visible={pageLoading} />}
			<div className="event-list">
				{eventStep && eventStep === 'eventList' && (
					<ListEvents
						logOutFn={logOut}
						eventsData={eventsData}
						showEventFn={showEvent}
					/>
				)}
				{eventStep && eventStep === 'eventDetail' && eventData && (
					<DetailEvent
						stepBackFn={stepBack}
						eventData={eventData}
						nextStepFn={nextStep}
					/>
				)}

				{eventStep && eventStep === 'accessControl' && eventData && (
					<AccessControl
						stepBackFn={stepBack}
						eventData={eventData}
						scanFn={scan}
					/>
				)}

				{eventStep && eventStep === 'roomControl' && eventData && (
					<RoomControl
						stepBackFn={stepBack}
						eventData={eventData}
						scanFn={scan}
					/>
				)}
				{eventStep && eventStep === 'seatsControl' && eventData.eventoLugar && (
					<SeatsControl
						stepBackFn={stepBack}
						eventId={eventId}
						eventData={eventData}
						mapViewFn={mapview}
						setEventData={setEventData}
					/>
				)}
				{eventStep && eventStep === 'scanner' && eventData && (
					<QRScanPage
						titleData={titleData}
						stepBackFn={stepBack}
						eventData={eventData}
						salaID={viewSalaId}
						accesoID={viewAccessId}
					/>
				)}

				{eventStep && eventStep === 'mapView' && eventData && (
					<MapView
						roomData={roomData}
						stepBackFn={stepBack}
						eventData={eventData}
					/>
				)}
			</div>
		</>
	);
};

export default AccessEventPage;
