import React from 'react';

import { Col, Row } from 'reactstrap';

const AccessDeniedPage = () => {
	return (
		<>
			<div className="denied-page">
				<Row>
					<Col lg="12" className="mt-2 mb-2">
						<div className='ball'><i className='fticon-close'></i></div>
						<h2>Ups!</h2>
						<h4>No tienes permisos para acceder a la aplicación.</h4>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default AccessDeniedPage;
