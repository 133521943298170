import React, { useState, useEffect } from 'react';

//Enrutado
import { Route } from 'react-router-dom';

//service
import * as usersApi from '../api/usersApi';

//Notificaciones
import { ToastContainer } from 'react-toastify';

//Loader
import LoadingLayer from './loaders/LoadingLayer';

//Componentes
import { Container, Row, Col } from 'reactstrap';

//mSal
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { handleMsalLogin } from '../context/msalConfig';

//denegado
import AccessDeniedPage from '../pages/error/AccessDeniedPage';

const Layout = ({ component: Component, ...props }) => {
			const [isLoading, setIsLoading] = useState(true);
			const { instance, inProgress, accounts } = useMsal();
			const [userApp, setUserApp] = useState(null);
		
			useEffect(() => {
				if (inProgress === InteractionStatus.None) {
					const usernameAAD = accounts[0] && accounts[0].username;
					if (usernameAAD) {
						getCurrentUser(usernameAAD);
					} else {
						handleMsalLogin(instance);
					}
				}
			}, [accounts, inProgress, instance]);
		
			const getCurrentUser = async (userEmail) => {
				const data = await usersApi.getCurrentUser(userEmail);
				setUserApp(data.data);
				setIsLoading(false);
			};
		
			const canView =
				userApp && userApp.id
					? userApp.modulos.find((md) => md === 'Azafatas')
						? true
						: false
					: false;

	return isLoading ? (
		<LoadingLayer visible={isLoading} />
	) : (
		<>
			<ToastContainer
				position="top-right"
				autoClose={4000}
				hideProgressBar
				newestOnTop={false}
				closeButton={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			{canView ? (
				<Container fluid className="main-container">
					<Row>
						<Col lg="12" className="p-0">
							<Route
								render={(properties) => (
									<Component {...props} userData={userApp} />
								)}
							/>
						</Col>
					</Row>
				</Container>
			) : (
				<AccessDeniedPage />
			)}
		</>
	);
};

export default Layout;
