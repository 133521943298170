import React, { useEffect, useState } from 'react';

import { Col, Row, Container, Button } from 'reactstrap';

//services
import * as accessApi from '../../api/accessEventApi';

//componentes
import PageHeader from '../../components/common/elems/PageHeader';
import LoadingLayer from '../../components/loaders/LoadingLayer';
import { QrReader } from 'react-qr-reader';

//notifications
import * as notify from '../../utils/notify';

const QRScanPage = ({ titleData, stepBackFn, eventData, salaID, accesoID }) => {
	const [isLoading, setIsLoading] = useState(false);

	const [showQrScanner, setShowQrScanner] = useState(false);
	const [showQrResults, setShowQrResults] = useState(false);
	const [qrResults, setQrResults] = useState(null);

	//const [qrPageCapacities, setQrPageCapacities] = useState({asistentesTotal: 0, asistentesDentro: 0, asistentesPorEntrar: 0});

	const checkStatusEntrada = async (dataUID) => {
		setIsLoading(true);

		const scanData = {
			eventoId: eventData.id,
			codigoQR: dataUID,
			accesoId: accesoID,
			salaId: salaID,
		};

		const data = await accessApi.scanEntrada(scanData);

		if (data && data.succeeded) {
			setQrResults(data.data);
			setShowQrResults(true);
			//updateTotals();
		} else {
			notify.error(`Ups! Ha ocurrido un error. ${data.message}`);
		}

		setIsLoading(false);
	};

	const allowAccess = async (asistenteID) => {
		setIsLoading(true);
		const data = await accessApi.permitirAcceso(asistenteID);

		if (data && data.succeeded) {
			setShowQrResults(false);
			//updateTotals();
		} else {
			notify.error(`Ups! Ha ocurrido un error. ${data.message}`);
		}
		setIsLoading(false);
	};

	// useEffect(() => {
	// 	updateTotals();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// const updateTotals = async () => {
	// 	const data = await accessApi.updateCapacities({
	// 		eventoId: eventData.id,
	// 		accesoId: accesoID,
	// 		salaId: salaID,
	// 	});

	// 	if (data && data.succeeded) {
	// 		setQrPageCapacities(data.data);
	// 	} else {
	// 		notify.error(`Ups! Ha ocurrido un error. ${data.message}`);
	// 	}
	// };

	return (
		<>
			{isLoading && <LoadingLayer visible={isLoading} />}

			{!showQrResults ? (
				<>
					<PageHeader title={titleData} stepBackFn={stepBackFn} />
					<Container className="event-body">
						<div className="scan-page">
							{showQrScanner ? (
								<Row className="mb-4">
									<QrReader
										onResult={(result, error) => {
											if (result && result.text) {
												checkStatusEntrada(result.text);
												setShowQrScanner(!showQrScanner);
											}
										}}
										style={{ width: '100%' }}
										constraints={{
											facingMode: 'environment',
										}}
									/>
								</Row>
							) : (
								<Row className="mb-4">
									<Col md="12">
										<div className="placeholder-scanner">
											<i className="fticon-scan"></i>

											<div className="text-scan">Escanear QR de acceso</div>
											<div className="btn-scan">
												<Button
													className="btn-prim-dark"
													onClick={() => setShowQrScanner(!showQrScanner)}
												>
													Escanear
												</Button>
											</div>
										</div>
									</Col>
								</Row>
							)}

							{/* <Row>
								<div className="div-control-aforo">
									<Row className="div-control-seats">
										<Col md="6" className="mb-4">
											<div className="box-div to-enter">
												<div className="iconuser-toEnter">
													<span className="fticon-user"></span>
												</div>
												<div>
													<span className="guest-toEnter">{qrPageCapacities.asistentesPorEntrar||0}</span>
													<span className="guest-total">/{qrPageCapacities.asistentesTotal||0}</span>
												</div>
												<div>
													<span>Por entrar</span>
												</div>
											</div>
										</Col>
										<Col md="6" className="mb-4">
											<div className="box-div into">
												<div className="iconuser-into">
													<span className="fticon-user"></span>
												</div>
												<div>
													<span className="guest-into">{qrPageCapacities.asistentesDentro||0}</span>
													<span className="guest-total">/{qrPageCapacities.asistentesTotal||0}</span>
												</div>
												<div>
													<span>Dentro</span>
												</div>
											</div>
										</Col>
									</Row>
								</div>
							</Row> */}
						</div>
					</Container>
				</>
			) : (
				<>
					<PageHeader
						title={titleData}
						stepBackFn={() => setShowQrResults(!showQrResults)}
					/>
					<Container className="event-body">
						<div className="scan-results">
							<Row>
								<Col md="8" className="mx-auto mb-4">
									<div className={`icon-status ${qrResults.estadoAcceso}`}>
										{qrResults.estadoAcceso === 'confirmado' ? (
											<div>
												<i className="fticon-confirm"></i>
												<h3>Confirmado</h3>
											</div>
										) : qrResults.estadoAcceso === 'incorrecto' ? (
											<div>
												<i className="fticon-state-cancel"></i>
												<h3>Acceso incorrecto</h3>
											</div>
										) : (
											<div>
												<i className="fticon-state-cancel"></i>
												<h3>Rechazado</h3>
											</div>
										)}
									</div>
								</Col>

								{qrResults.entradaModificada && (
									<Col md="8" className="mx-auto mb-4">
										<div className="warning-acceso">
											<i className="fticon-alert"></i>
											<p>
												Los datos de la invitación no coinciden con los datos de
												acceso porque este invitado ha sido reubicado. Por
												favor, comunícale su nueva ubicación.
											</p>
										</div>
									</Col>
								)}

								<Col md="8" className="mx-auto">
									<div className="whitebox entrada">
										<div className="status">
											{qrResults.estadoAcceso === 'confirmado' ? (
												<div className="sticker sticker-green">
													{qrResults.nombreAcceso}
												</div>
											) : qrResults.estadoAcceso === 'incorrecto' ? (
												<div className="sticker sticker-orange">
													{qrResults.reasonAcceso}
												</div>
											) : (
												<div className="sticker sticker-red">
													{qrResults.reasonAcceso}
												</div>
											)}
										</div>

										{qrResults.invitado && (
											<div className="mt-4">
												<h1>{qrResults.invitado}</h1>
												<p>{qrResults.procedencia}</p>
												<div className="ubicacion">
													<div className="box sala">
														<div
															className={`bullet bgs-${qrResults.colorSala}`}
														></div>
														<span>{qrResults.nombreSala}</span>
													</div>

													{qrResults.zona && (
														<div className="box zona">
															<div
																className={`bullet bgs-${qrResults.colorArea}`}
															></div>
															<span>{qrResults.zona}</span>
														</div>
													)}

													{qrResults.fila && (
														<>
															<div className="fila">
																<span>Fila</span>
																<span>{qrResults.fila}</span>
															</div>
															<div className="butaca">
																<span>Butaca</span>
																<span>{qrResults.butaca}</span>
															</div>
														</>
													)}
												</div>
											</div>
										)}
										{qrResults.permitirAcceso && (
											<div className="mt-3">
												<Button
													className="btn-sec-dark"
													onClick={() => allowAccess(qrResults.asistenteId)}
												>
													Permitir acceso
												</Button>
											</div>
										)}
									</div>
								</Col>
								<Col md="8" className="mx-auto text-center mb-5">
									<Button
										className="btn-prim-dark"
										onClick={() => {
											setShowQrResults(!showQrResults);
											setShowQrScanner(!showQrScanner);
										}}
									>
										Escanear
									</Button>
								</Col>
							</Row>
						</div>
					</Container>
				</>
			)}
		</>
	);
};

export default QRScanPage;
