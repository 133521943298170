import * as notify from '../utils/notify';
import { getAuthHeaders } from '../context/msalConfig';

const callApi = async (url, options, noJson) => {
	try {
		const response = await fetch(url, options);
		if (!response.ok) handleError(response);
		else {
			if (noJson) return response.text();
			const data = await response.json();
			return data;
		}
	} catch (e) {
		console.log('catchUrl', url);
		console.error('catchError', e);
		notify.error('No se ha podido obtener respuesta del servidor.');
	}
};

const handleError = (response) => {
	console.log('handleError: ', response);
	notify.error(`Error ${response.status}: ${response.statusText}`);
};

export const callApiPost = async (url, data) => {
	return await callApi(url, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: getAuthHeaders()
	});
};

export const callApiGet = async (url) => {
	return await callApi(url, {
		method: 'GET',
		headers: getAuthHeaders()
	});
};
