import React from 'react';

const Seat = ({ seatDetails }) => {
	return (
		<>
			<div
				className={`row-seat ${seatDetails.tipo || 'seat'} ${
					seatDetails.section ? seatDetails.section.type : ''
				} ${
					seatDetails.tooltipSeat && seatDetails.tooltipSeat.color
						? `bgs-${seatDetails.tooltipSeat.color}`
						: ''
				} ${seatDetails.sentado ? 'in-seat' : ''}`}
			>
				<span>
					{seatDetails.tipo === 'seat' &&
						seatDetails.tooltipSeat &&
						seatDetails.tooltipSeat.ocupado && <i className="fticon-tick"></i>}
				</span>
			</div>
		</>
	);
};

export default Seat;
