import React from 'react';

import { Col, Row, Container } from 'reactstrap';

//componentes
import PageHeader from '../../components/common/elems/PageHeader';

const RoomControl = ({ stepBackFn, eventData, scanFn }) => {
	return (
		<>
			<PageHeader title={'Acceso a la sala'} stepBackFn={stepBackFn} />
			<Container className='event-body'>
				<Row>
					{eventData.eventoLugar &&
						eventData.eventoLugar.accesos &&
						eventData.eventoLugar.accesos.length > 0 &&
						eventData.eventoLugar.accesos.map(
							(acceso) =>
							
								acceso.salas &&
								acceso.salas.length > 0 &&
								acceso.salas.map((sala, indexSala) => (
									<Col className="mb-4" md="12" key={indexSala}>
										<div
											className="box-access-site"
											onClick={() =>
												scanFn('roomControl', sala.nombre, acceso.id, sala.id)
											}
										>
											<div className="info">
												<div className="option-img-room">
													<span className="fticon-sitting"></span>
												</div>
												<div className="option-text">
													<span>{sala.nombre}</span>
												</div>
											</div>

											<div className="option-text-arrow">
												<span className="fticon-arrow-right"></span>
											</div>
										</div>
									</Col>
								))
						)}
				</Row>
			</Container>
		</>
	);
};

export default RoomControl;
