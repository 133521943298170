//Global CSS
import './css/app.scss';

//Enrutado
import { MsalProvider } from "@azure/msal-react";
import {
	BrowserRouter as Router,
	Switch,
	Redirect,
	Route,
} from 'react-router-dom';
import Layout from './components/Layout';

//Genericos
import AccessDeniedPage from './pages/error/AccessDeniedPage';
import NotFoundPage from './pages/error/NotFoundPage';

import Access from './pages/AccessEventPage';

const App = ({ pca }) => {
	return (
	  <MsalProvider instance={pca}>
		 <Router>
		 <Switch>
					<Route exact path="/"><Redirect to="/eventos" /></Route>
					<Layout path="/eventos" component={Access} />
					<Layout path="/restringido" component={AccessDeniedPage} />
					<Layout component={NotFoundPage} />
				</Switch>
			</Router>
	  </MsalProvider>
	);
 }

 export default App;