import React from 'react';

import { Col, Row, Container } from 'reactstrap';

//componentes
import PageHeader from '../../components/common/elems/PageHeader';

const AccessControl = ({ stepBackFn, eventData, scanFn }) => {
	return (
		<>
			<PageHeader title={'Acceso al recinto'} stepBackFn={stepBackFn} />

			<Container className="event-body">
				<Row>
					{eventData.eventoLugar &&
						eventData.eventoLugar.accesos &&
						eventData.eventoLugar.accesos.length > 0 &&
						eventData.eventoLugar.accesos.map((acceso, index) => (
							<Col md="12" className="mb-4" key={index}>
								<div
									className="box-access-site"
									onClick={() =>
										scanFn('accessControl', acceso.nombre, acceso.id, null)
									}
								>
									<div className="info">
										<div className="option-img-access">
											<span className="fticon-location"></span>
										</div>
										<div className="option-text">
											<span>{acceso.nombre}</span>
										</div>
									</div>

									<div className="option-text-arrow">
										<span className="fticon-arrow-right"></span>
									</div>
								</div>
							</Col>
						))}
				</Row>
			</Container>
		</>
	);
};

export default AccessControl;
