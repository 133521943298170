//router
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

//mSal
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './context/msalConfig';

//main app
import App from './App';

export const mainMsalInstance = new PublicClientApplication(msalConfig);
const rootElement = document.getElementById('root');

//helpers mSal defaultAccount
if (!mainMsalInstance.getActiveAccount() && mainMsalInstance.getAllAccounts().length > 0) {
  mainMsalInstance.setActiveAccount(mainMsalInstance.getAllAccounts()[0]);
}

mainMsalInstance.enableAccountStorageEvents();

mainMsalInstance.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		const account = event.payload.account;
		mainMsalInstance.setActiveAccount(account);
	}
});
//------------------------------

ReactDOM.render(
	<BrowserRouter>
		<App pca={mainMsalInstance} />
	</BrowserRouter>,
	rootElement
);
