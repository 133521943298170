import React, { useState, useEffect } from 'react';
import * as accessApi from '../../api/accessEventApi';
import { Col, Row, Container } from 'reactstrap';

//componentes
import PageHeader from '../../components/common/elems/PageHeader';
import LoadingLayer from '../../components/loaders/LoadingLayer';

//notifications
import * as notify from '../../utils/notify';

const SeatsControl = ({ stepBackFn, eventId, eventData, mapViewFn, setEventData}) => {
	const [pageLoading, setPageLoading] = useState(false);

	useEffect(() => {
		getEvent(eventId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getEvent = async (eventId) => {
		setPageLoading(true);
		const data = await accessApi.getEvent(eventId);
		if (data && data.succeeded) {
			setEventData(data.data);	
		} else {
			notify.error(`Ups! Ha ocurrido un error. ${data.message}`);
		}
		setPageLoading(false);
	};
	return (
		<>
		{pageLoading && <LoadingLayer visible={pageLoading} />}
			<PageHeader title={'Control de aforo'} stepBackFn={stepBackFn} />
			<Container className="event-body">
				<div className="div-control-aforo">
					<Row>
						<Col md="12" className="mb-4">
							<div className="box-tit-control">
								<div className="control-tit-site">
									<span>{eventData.eventoLugar.nombre}</span>
								</div>
								<div className="option-aforo">
									<span className="aforo-tit">Libres</span>
									<span className="aforo-data">
										{eventData.eventoLugar.butacasLibres}	
									</span>
								</div>
							</div>
						</Col>
					</Row>
					<Row className="div-control-seats">
						<Col  className="mb-4">
							<div className="box-div to-enter">
								<div className="iconuser-toEnter">
									<span className="fticon-user"></span>
								</div>
								<div>
									<span className="guest-toEnter">{eventData.eventoLugar.asistentesPorEntrar}</span>
									<span className="guest-total">/{eventData.eventoLugar.totalAsistentes}</span>
								</div>
								<div>
									<span>Por entrar</span>
								</div>
							</div>
						</Col>
						<Col  className="mb-4">
							<div className="box-div into">
								<div className="iconuser-into">
									<span className="fticon-user"></span>
								</div>
								<div>
									<span className="guest-into">{eventData.eventoLugar.asistentesDentro}</span>
									<span className="guest-total">/{eventData.eventoLugar.totalAsistentes}</span>
								</div>
								<div>
									<span>Dentro</span>
								</div>
							</div>
						</Col>
					</Row>

					<Row>
						{eventData.eventoLugar &&
							eventData.eventoLugar.accesos &&
							eventData.eventoLugar.accesos.length > 0 &&
							eventData.eventoLugar.accesos.map(
								(acceso) =>
									acceso.salas &&
									acceso.salas.length > 0 &&
									acceso.salas.map((sala, indexSala) => (
										<Col key={indexSala} md="12">
											<div
												className="box-site"
												onClick={() =>
													mapViewFn(sala)
												}
											>
												<Row>
													<Col md="1" xs="1" className="text-center icono-location">
														<div className="option-img-location">
															<i className="fticon-location"></i>
														</div>
													</Col>
													<Col md="2" xs="1" className='texto-sala'>
														<div className="option-title">
															<span>{sala.nombre}</span>
														</div>
													</Col>
													<Col xs="1">
														<div className="option-text-arrow mobile">
															<span className="fticon-arrow-right"></span>
														</div>
													</Col>
													<Col md="7">
														<div className="option-sites">
															<div className="option-box libres">
																<div className="bullet"></div>
																<div className="box-sites">
																	<div className="text">
																		<span>Libres</span>
																	</div>
																	<div className="text-3">													
																		{sala.butacasLibres}
																	</div>
																</div>
															</div>
															<div className="option-box toEnter">
																<div className="iconuser">
																	<span className="fticon-user"></span>
																</div>
																<div className="box-sites">
																	<div className="text">
																		<span>Por entrar</span>
																	</div>
																	<div className="text-3">
																		<span className="text-5">{sala.asistentesPorEntrar}</span>
																		/{sala.totalAsistentes}
																	</div>
																</div>
															</div>
															<div className="option-box into">
																<div className="iconuser2">
																	<span className="fticon-user"></span>
																</div>
																<div className="box-sites">
																	<div className="text">
																		<span>Dentro</span>
																	</div>
																	<div className="text-3">
																		<span className="text-6">{sala.asistentesDentro}</span>
																		/{sala.totalAsistentes}
																	</div>
																</div>
															</div>
														</div>
													</Col>
													<Col md="1" className="text-end no-mobile">
														<div className="option-text-arrow">
															<span className="fticon-arrow-right"></span>
														</div>
													</Col>
												</Row>
											</div>
										</Col>
									))
							)}
					</Row>
				</div>
			</Container>
		</>
	);
};

export default SeatsControl;
