import React from 'react';

import { Button } from 'reactstrap';

const PageHeader = ({
	title,
	stepBackFn,
	disabledButtons,
	logoutFn,
	...props
}) => {
	return (
		<div className="app-header">
			<div className="info">
				{stepBackFn && (
					<div
						className={`step-back ${disabledButtons ? 'step-disabled' : ''}`}
						onClick={() => !disabledButtons && stepBackFn && stepBackFn()}
					>
						<i className="fticon-arrow-left"></i>
					</div>
				)}
				<div className="title">{title}</div>
			</div>
			<div className="action-buttons me-2">
				{props.children}
				{logoutFn && (
					<Button
						type="submit"
						onClick={logoutFn}
						className="btn-prim-dark"
						disabled={disabledButtons}
					>
						Cerrar sesión
					</Button>
				)}
			</div>
		</div>
	);
};

export default PageHeader;
